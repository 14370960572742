import React, { useEffect } from "react"; //{ useEffect }
import { Helmet } from "react-helmet";

import "normalize.css";
import "../styles/fonts.css";

interface DefaultLayoutProps {
  title?: string;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, title }) => {
  useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setExcludedQueryParams", ["CFID", "CFTOKEN"]]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      var u = "https://stats.goeast.ch/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", "14"]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    })();
  }, []);
  return (
    <>
      <Helmet>
        <title>{title ? title + " – WigaSoft AG" : "WigaSoft AG"}</title>
        <meta
          name="description"
          content="WigaSoft AG - seit 1987 tragen unsere Software-Lösungen nachweislich dazu bei, die Mitarbeiter der stationären Versorger wie Spitäler, Rehakliniken, Alters-/Pflegeheime sowie sozialpädagogische Institutionen spürbar zu entlasten." />
        <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
        <meta http-equiv="Accept-CH" content="DPR, Viewport-Width, Width" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://wigasoft-prod.imgix.net/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://wigasoft-prod.imgix.net/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://wigasoft-prod.imgix.net/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicon/safari-pinned-tab.svg"
          color="#ff6a39"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <script src="/js/libs/jquery.min-lightbox-wigasoft.js"></script>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title ? `${title} – WigaSoft AG` : 'WigaSoft AG'} />
        <meta property="og:description" content="WigaSoft AG - seit 1987 tragen unsere Software-Lösungen nachweislich dazu bei, die Mitarbeiter der stationären Versorger wie Spitäler, Rehakliniken, Alters-/Pflegeheime sowie sozialpädagogische Institutionen spürbar zu entlasten." />
        <meta property="og:image" content="/assets/images/wigasoft-bildmarke.svg" />
      </Helmet>
      <Helmet>
        <script src="/js/libs/css-polyfills.js"></script>
      </Helmet>
      <div>{children}</div>
    </>
  );
};

export default DefaultLayout;
